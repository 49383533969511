import {useLocalization} from "../context/localization";
import {useAccountGroupApi} from "../data/account-group";
import {useEffect, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Select} from "@mlyngvo/common-ui";
import React from "react";
import {Option} from "@mui/joy";

interface AccountGroupSelectProps {
    clubId: string;
    onChange: (groupId?: string) => void;
    defaultGroupId?: string;
    disabled?: boolean;
}

export function AccountGroupSelect({clubId: pClubId, onChange, defaultGroupId, disabled}: AccountGroupSelectProps) {
    const {t} = useLocalization();
    const {listOptions, find} = useAccountGroupApi();

    const [groupId, setGroupId] = useState<string>();

    const {result: options, execute} = useAsyncCallback(async (clubId: string) => await listOptions(clubId));

    useEffect(() => {
        if (!disabled) {
            execute(pClubId)
                .then(() => {
                    setGroupId(undefined);
                    onChange(undefined);
                })
                .catch(console.error);
        }
    }, [pClubId, disabled, execute]);

    useEffect(() =>
        {
            if (!disabled
                && typeof defaultGroupId === 'string'
                && defaultGroupId !== groupId) {
                if (defaultGroupId.length > 0 && options) {
                    find(defaultGroupId)
                        .then(g => setGroupId(g?.id))
                        .catch(console.error);
                } else {
                    setGroupId(undefined);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pClubId, disabled, defaultGroupId, options]);

    function handleGroupId(id: string|null) {
        setGroupId(id ?? undefined);
        onChange(id ?? undefined);
    }

    return (
        <Select
            emptyValue
            label={t('accountGroups.singular')}
            options={options?.map(o => o.id) ?? []}
            renderOption={id => (
                <Option key={id} value={id}>{options?.find(o => o.id === id)?.name}</Option>
            )}
            FormControlProps={{
                size: 'sm',
                disabled: disabled
            }}
            SelectProps={{
                size: 'sm',
                slotProps: {
                    button: {
                        sx: {
                            whiteSpace: 'nowrap'
                        }
                    }
                },
                value: groupId ?? '',
                onClear: () => { handleGroupId(null); },
                onChange: (_, value) => { handleGroupId(value); }
            }}
            i18n={{
                allLabel: t('common.all')
            }}
        />
    )
}