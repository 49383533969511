import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Body, Breadcrumbs, DateDisplay, Tabs, Title, useFlag, useTabs} from '@mlyngvo/common-ui';
import {
    Avatar,
    Box,
    Button,
    Chip, ColorPaletteProp,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    ListItemDecorator,
    Option,
    Sheet,
    Snackbar,
    Stack,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import {debounce} from 'lodash';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import {AccountGroupTimeframe} from './account-group-timeframe';
import {useClubApi} from '../../data/club';
import {displayAmount, notBlank, notNull} from '../../utils';
import {type AccountGroup, useAccountGroupApi} from '../../data/account-group';
import {useLocalization} from '../../context/localization';
import {type AccountOptionView, useAccountApi} from '../../data/account';
import {FormModal} from '../../component/form-modal';
import {Input} from '../../component/input';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {Select} from '../../component/select';
import {ClubTypeChip} from '../club';
import {type DeviceListView, type DevicePortConfig, DeviceType, useDeviceApi} from '../../data/device';
import {CostAdjustmentDirection, type CostAdjustmentItem, CostAdjustmentType} from '../../data/common';
import {Checkbox} from '../../component/checkbox';
import {DeviceTypeChip} from '../device/device-type-chip';
import {StatusChip} from '../../component/status-chip';
import {RouterLink} from '../../component/link';
import {CostAdjustmentInput} from "../account/cost-adjustment-input";

const TabsKey = 'account_group_tabs';

export function AccountGroupDetails() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find} = useAccountGroupApi();

    const {tab, changeTab} = useTabs(`${TabsKey}:${id}`);

    const {result: group, error, loading, execute} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('accountGroups.title'), onClick: () => { navigate('/account-groups'); } },
                        { label: group === undefined ? t('common.details') : group.name }
                    ]}
                />
            )}
            title={(
                <Title
                    title={
                        group === undefined ? t('accountGroups.singular') : group.name
                    }
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<EditRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/account-groups/${group?.id}/edit`); }}
                            disabled={role === UserRole.User}
                        >
                            {t('actions.edit')}
                        </Button>
                    )}
                />
            )}
            {...{ error, loading }}
        >
            <Tabs
                value={tab}
                onChange={changeTab}
                items={[
                    (<Typography key="tab-0" level="body-sm" noWrap><strong>{t('common.overview')}</strong></Typography>),
                    (<Typography key="tab-1" level="body-sm" noWrap><strong>{t('accounts.title')}</strong></Typography>),
                    (<Typography key="tab-2" level="body-sm" noWrap><strong>{t('devices.title')}</strong></Typography>)
                ]}
                expandMx={{
                    xs: -2,
                    md: -6
                }}
            />

            {group !== undefined && (
                <Box
                    mt={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflow: 'auto',
                    }}
                >
                    {tab === 0 && (
                        <Stack
                            direction={{ xs: 'column', lg: 'row' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            <Stack
                                direction="column"
                                rowGap={3}
                                flexGrow={4}
                            >
                                <AccountGroupInfos {...group} />
                            </Stack>
                            <Stack
                                direction="column"
                                rowGap={3}
                                flexGrow={1}
                            >
                                <AccountGroupSettings {...group} />
                            </Stack>
                        </Stack>
                    )}

                    {tab === 1 && (
                        <AccountGroupAccounts
                            {...group}
                            refresh={async () => {await execute(id);}}
                        />
                    )}

                    {tab === 2 && (
                        <AccountGroupDevices
                            {...group}
                            refresh={async () => {await execute(id);}}
                        />
                    )}
                </Box>
            )}
        </Body>
    );
}


/*
 * Overview
 */
type AccountGroupSettingsProperties = Pick<AccountGroup, 'totalAccounts'|'expirationDate'|'startHour'|'endHour'>;

function AccountGroupSettings({totalAccounts, expirationDate, startHour, endHour}: AccountGroupSettingsProperties) {
    const {t, language} = useLocalization();
    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.settings')}</Typography>
                <Typography level="body-sm">{t('accountGroups.settingsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('accounts.title')}</strong></Typography>
                    <Typography startDecorator={<GroupRoundedIcon/>}>
                        {totalAccounts}
                    </Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('accountGroups.expirationDate')}</strong></Typography>
                    {notBlank(expirationDate) && <DateDisplay value={expirationDate as string} locale={language} />}
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('accountGroups.activeTimeframe')}</strong></Typography>
                    <AccountGroupTimeframe hideLabel level="body-md" {...{ startHour, endHour }} />
                </div>
            </Stack>
        </Sheet>
    );
}

type AccountGroupInfosProperties = Pick<AccountGroup, 'name'|'clubId'|'defaultOnSignup'|'customRangefeeAmount'>;

function AccountGroupInfos({name, clubId, defaultOnSignup, customRangefeeAmount}: AccountGroupInfosProperties) {
    const {t} = useLocalization();
    const {find} = useClubApi();

    const {result: club} = useAsync(async () => await find(clubId), [clubId]);

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('accountGroups.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.name')}</strong></Typography>
                    <Typography>{name}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.singular')}</strong></Typography>
                    <List>
                        {club !== undefined && (
                            <ListItem
                                component={RouterLink}
                                href={`/clubs/${club.id}`}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    borderRadius: 7,
                                    py: 1,
                                }}
                            >
                                <Avatar size="sm" src={club.logoUrl} />
                                <ListItemContent
                                    component={Stack}
                                    direction="column"
                                    gap={0.5}
                                >
                                    <Typography level="body-xs">{club.name}</Typography>
                                    <ClubTypeChip type={club.type}/>
                                </ListItemContent>
                                <KeyboardArrowRightRoundedIcon />
                            </ListItem>
                        )}
                    </List>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('accountGroups.defaultOnSignup')}</strong></Typography>
                    <StatusChip enabled={defaultOnSignup} />
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('transactions.customRangefeeAmount')}</strong></Typography>
                    {notBlank(customRangefeeAmount) ? `€ ${  displayAmount(customRangefeeAmount as number)}` : '-'}
                </div>
            </Stack>
        </Sheet>
    );
}


/*
 * Accounts
 */
type AccountGroupAccountsProperties = Pick<AccountGroup, 'id'|'accounts'|'clubId'> & { refresh: () => Promise<void> };

function AccountGroupAccounts({id, accounts, clubId, refresh}: AccountGroupAccountsProperties) {
    const {t} = useLocalization();
    const {listOptions} = useAccountApi();
    const {putAccounts} = useAccountGroupApi();

    const [assignMode, setAssignMode, clearAssignMode] = useFlag(false);

    const [uNeedle, setUNeedle] = useState('');
    const [aNeedle, setANeedle] = useState('');

    const {result: options, loading} = useAsync(async () =>
        await listOptions(clubId, uNeedle)
        , [clubId, uNeedle]);

    const [assigned, setAssigned] = useState<AccountOptionView[]>([]);

    useEffect(() => {
        setAssigned(accounts.map(a => ({ id: a.accountId, name: a.accountName })));
    }, [accounts]);

    const handleUNeedle = debounce((value: string) => { setUNeedle(value); }, 300);

    function handleAssignment(o: AccountOptionView) {
        if (!assigned.some(a => a.id === o.id)) {
            setAssigned(v => [...v, o]);
        }
    }

    function handleUnassignment(o: AccountOptionView) {
        setAssigned(v => v.filter(a => a.id !== o.id));
    }

    async function handleSubmit(event_: React.FormEvent) {
        event_.preventDefault();

        if (assignMode) {
            await putAccounts(id, assigned.map(a => a.id));
            await refresh();
            clearAssignMode();
        }
    }

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={2}
            >
                <Button
                    size="sm"
                    variant="outlined"
                    startDecorator={<AppRegistrationRoundedIcon />}
                    onClick={setAssignMode}
                >
                    {t('accountGroups.addRemoveAccounts')}
                </Button>
            </Stack>
            <Box my={1} />
            <Sheet
                variant="outlined"
                sx={{ overflow: 'hidden' }}
            >
                <List size="sm">
                    {accounts.length === 0 && (
                        <ListItem>{t('accountGroups.noAccountsAssigned')}</ListItem>
                    )}
                    {accounts.map(a => (
                        <ListItem
                            key={a.accountId}
                            component={RouterLink}
                            href={`/accounts/${a.accountId}`}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                borderRadius: 7,
                                py: 1,
                            }}
                        >
                            <Avatar size="sm" src={`/api/accounts/${a.accountId}/avatar`} />
                            <ListItemContent
                                component={Stack}
                                direction="column"
                            >
                                <Typography level="body-sm"><strong>{a.accountName}</strong></Typography>
                                <Chip size="sm" color="primary" variant="outlined" sx={{ fontFamily: 'monospace' }}>{a.accountId}</Chip>
                            </ListItemContent>
                            <KeyboardArrowRightRoundedIcon />
                        </ListItem>
                    ))}
                </List>
            </Sheet>
            <FormModal
                fixedTop
                open={assignMode}
                onCancel={clearAssignMode}
                onSave={handleSubmit}
            >
                <Typography level="title-lg">{t('accountGroups.addRemoveAccounts')}</Typography>
                <Divider />
                <Grid container spacing={2} sx={{ overflow: 'hidden' }}>
                    <Grid xs={6}>
                        <Typography gutterBottom>{t('common.unassigned')}</Typography>
                        <Input
                            InputProps={{
                                size: 'sm',
                                placeholder: 'Search',
                                onChange: event_ => handleUNeedle(event_.target.value)
                            }}
                        />
                        <Box my={1}><Divider /></Box>
                        {loading && <LinearProgress />}
                        <List
                            size="sm"
                            sx={{
                                maxHeight: '60dvh',
                                overflow: 'auto'
                            }}
                        >
                            {options
                                ?.filter(o => !assigned.some(a => a.id === o.id))
                                .map(o => (
                                    <ListItemButton
                                        key={o.id}
                                        sx={{
                                            borderRadius: 7,
                                            py: 1,
                                        }}
                                        onClick={() => { handleAssignment(o); }}
                                    >
                                        <ListItemDecorator sx={{ mr: 0.3 }}>
                                            <Avatar size="sm" src={`/api/accounts/${o.id}/avatar`} />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="body-sm" noWrap><strong>{o.name}</strong></Typography>
                                        </ListItemContent>
                                        <KeyboardArrowRightRoundedIcon />
                                    </ListItemButton>
                                )
                            )}
                        </List>
                    </Grid>
                    <Grid xs={6}>
                        <Typography textAlign="right" gutterBottom>{t('common.assigned')}</Typography>
                        <Input
                            InputProps={{
                                size: 'sm',
                                placeholder: 'Search',
                                onChange: event_ => { setANeedle(event_.target.value); },
                                sx: {
                                    '& > input': {
                                        textAlign: 'right'
                                    }
                                }
                            }}
                        />
                        <Box my={1}><Divider /></Box>
                        <List
                            size="sm"
                            sx={{
                                maxHeight: '60dvh',
                                overflow: 'auto'
                            }}
                        >
                            {assigned
                                .filter(a => !notBlank(aNeedle) || a.name?.toLowerCase()?.includes(aNeedle.toLowerCase()) === true)
                                .map(o => (
                                    <ListItemButton
                                        key={o.id}
                                        sx={{
                                            borderRadius: 7,
                                            py: 1,
                                        }}
                                        onClick={() => { handleUnassignment(o); }}
                                    >
                                        <KeyboardArrowLeftRoundedIcon />
                                        <ListItemContent>
                                            <Typography level="body-sm" textAlign="right" noWrap><strong>{o.name}</strong></Typography>
                                        </ListItemContent>
                                        <ListItemDecorator sx={{ mr: 0.3 }}>
                                            <Avatar size="sm" src={`/api/accounts/${o.id}/avatar`} />
                                        </ListItemDecorator>
                                    </ListItemButton>
                                )
                            )}
                        </List>
                    </Grid>
                </Grid>
            </FormModal>
        </>
    );
}


/*
 * Devices
 */
type AccountGroupDevicesProperties = Pick<AccountGroup, 'id'|'clubId'|'devices'|'costAdjustments'> & { refresh: () => Promise<void> }

function AccountGroupDevices({id, clubId, devices: permissions, costAdjustments, refresh}: AccountGroupDevicesProperties) {
    const {t} = useLocalization();
    const {find} = useClubApi();
    const {list} = useDeviceApi();
    const {setPermission, setAccountCostAdjustment} = useAccountGroupApi();

    const {result: [club, devices] = []} = useAsync(async () =>
            await Promise.all([
                find(clubId),
                list({ page: 0, size: 999, sort: { fields: ['name'], order: 'asc' }, filter: { clubId }})
            ])
        , [clubId]);

    const [permissionError, setPermissionError, clearPermissionError] = useFlag(false);

    const [costAdjustment, setCostAdjustment] = useState<CostAdjustmentItem>();

    function handlePermissionSubmit(deviceId: string, enabled: boolean) {
        setPermission(id, deviceId, enabled)
            .catch(() => { setPermissionError(); });
    }

    function handleCostAdjustmentChange(key: keyof CostAdjustmentItem, value: string|number) {
        if (costAdjustment !== undefined) {
            const data = (key === 'type' || key === 'direction')
                ? value
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                : Number.parseFloat((value || '0') as string);
            const adjustment = {
                ...costAdjustment,
                [key]: data,
            };
            if (key === 'direction') {
                if (value !== CostAdjustmentDirection.FixedPrice && !notNull(costAdjustment.type)) {
                    adjustment.type = CostAdjustmentType.Amount;
                }
            }
            setCostAdjustment(adjustment);
        }
    }

    async function handleCostAdjustmentSubmit(event_: React.FormEvent) {
        event_.preventDefault();

        if (costAdjustment !== undefined) {
            await setAccountCostAdjustment(id, costAdjustment.deviceId, costAdjustment);
            await refresh();
            setCostAdjustment(undefined);
        }
    }

    const device = useMemo(() => devices?.content.find(d => d.id === costAdjustment?.deviceId), [costAdjustment, devices]);

    return (
        <>
            <Typography
                level="title-sm"
                startDecorator={<CorporateFareRoundedIcon sx={{ top: '-4px', position: 'relative' }} />}
            >
                <strong>{club?.name ?? clubId}</strong>
            </Typography>
            <List>
                {devices?.content.map(d => {
                    const permission = permissions.find(p => p.deviceId === d.id);
                    const adjustment = costAdjustments.find(c => c.deviceId === d.id) ?? {
                        deviceId: d.id,
                        deviceType: d.type,
                        deviceName: d.name,
                        direction: CostAdjustmentDirection.Discount,
                        type: CostAdjustmentType.Percentage,
                        port0Amount: 0,
                        port1Amount: 0,
                        port2Amount: 0,
                        port3Amount: 0,
                    };
                    let color: ColorPaletteProp;
                    let prefix = '';
                    switch (adjustment.direction) {
                        case CostAdjustmentDirection.Discount:
                            color = 'success';
                            prefix = '-';
                            break;
                        case CostAdjustmentDirection.Markup:
                            color = 'warning';
                            prefix = '+';
                            break;
                        case CostAdjustmentDirection.FixedPrice:
                            color = 'primary';
                            break;
                    }

                    return (
                        <Fragment key={d.id}>
                            <ListItem
                                sx={theme => ({
                                    borderRadius: 5,
                                    '&:hover': {
                                        background: theme.palette.neutral.plainHoverBg
                                    }
                                })}
                            >
                                <Checkbox
                                    CheckboxProps={{
                                        defaultChecked: permission?.enabled ?? false,
                                        onChange: event_ => { handlePermissionSubmit(d.id, event_.target.checked); }
                                    }}
                                />
                                <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    columnGap={1}
                                    rowGap={0.5}
                                >
                                    <DeviceTypeChip type={d.type} />
                                    <Typography level="body-xs">{d.name}</Typography>

                                    {[adjustment.port0Amount, adjustment.port1Amount, adjustment.port2Amount, adjustment.port3Amount].map((a, index) =>
                                        a > 0
                                            ? (
                                                <Chip
                                                    key={`port-${  index}`}
                                                    size="sm"
                                                    variant="outlined"
                                                    startDecorator={`#${index}:`}
                                                    color={color}
                                                >
                                                    {prefix}{a} {adjustment.type === 'Percentage' ? '%' : '€'}
                                                </Chip>
                                            )
                                            : <span key={`port-${  index}`} />
                                    )}
                                </Stack>

                                <Box flexGrow={1} />

                                {d.type === DeviceType.BallVendingMachine && (
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => { setCostAdjustment(adjustment); }}
                                    >
                                        <PercentRoundedIcon sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                )}
                            </ListItem>
                        </Fragment>
                    );
                })}
            </List>
            <Box mt={2} mb={3} />

            <Snackbar
                variant="solid"
                color="danger"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={permissionError}
                onClose={clearPermissionError}
            >
                {t('permissions.setPermissionError')}
            </Snackbar>

            <FormModal
                open={costAdjustment !== undefined}
                onCancel={() => { setCostAdjustment(undefined); }}
                onSave={handleCostAdjustmentSubmit}
            >
                <Typography level="title-lg">{t('accounts.costAdjustment.title')}</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Typography level="body-sm" gutterBottom><strong>{t('devices.singular')}</strong></Typography>
                        <Typography>{costAdjustment?.deviceName}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Select
                            label={t('common.direction')}
                            options={Object.values(CostAdjustmentDirection)}
                            renderOption={o => (
                                <Option key={o} value={o}>{t(`accounts.costAdjustment.directions.${o}`)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: costAdjustment?.direction ?? CostAdjustmentDirection.Discount,
                                onChange: (_, value) => { handleCostAdjustmentChange('direction', value ?? CostAdjustmentDirection.Discount); },
                                renderValue: o =>
                                    o === null
                                        ? ''
                                        : t(`accounts.costAdjustment.directions.${o.value}`)
                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                    {costAdjustment?.direction !== CostAdjustmentDirection.FixedPrice && (
                        <Grid xs={12} sm={6}>
                            <Select
                                label={t('common.type')}
                                options={Object.values(CostAdjustmentType)}
                                renderOption={o => (
                                    <Option key={o} value={o}>{t(`accounts.costAdjustment.types.${o}`)}</Option>
                                )}
                                SelectProps={{
                                    placeholder: t('hints.select'),
                                    value: costAdjustment?.type ?? CostAdjustmentType.Percentage,
                                    onChange: (_, value) => { handleCostAdjustmentChange('type', value ?? ''); },
                                    renderValue: o =>
                                        o === null
                                            ? ''
                                            : t(`accounts.costAdjustment.types.${o.value}`)
                                }}
                                FormControlProps={{
                                    required: true
                                }}
                            />
                        </Grid>
                    )}
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    {[costAdjustment?.port0Amount, costAdjustment?.port1Amount, costAdjustment?.port2Amount, costAdjustment?.port3Amount].map((a, index) => {
                        const amount = device === undefined
                            ? 0
                            : (device[`port${index}` as keyof DeviceListView] as DevicePortConfig).amount ?? 0;

                        return (
                            <CostAdjustmentInput
                                key={`port-${  index}`}
                                baseAmount={amount}
                                adjustValue={a ?? 0}
                                onChange={v => handleCostAdjustmentChange(`port${index}Amount` as keyof CostAdjustmentItem, v)}
                                direction={costAdjustment?.direction}
                                type={costAdjustment?.type}
                            />
                        )
                    })}
                </Grid>
            </FormModal>
        </>
    );

}