import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListDivider,
    ListItemButton,
    ListItemContent, Option,
    Stack,
    Typography
} from '@mui/joy';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import React, {useState} from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {PlainTable} from '@mlyngvo/common-ui';
import {type Club, type TransactionBookmark, useClubApi} from '../../data/club';
import {useLocalization} from '../../context/localization';
import {displayAmount, notBlank} from '../../utils';
import {StatusChip} from '../../component/status-chip';
import {ItemMenu} from './generic-item-menu';
import {FormModal} from '../../component/form-modal';
import {Select} from '../../component/select';
import {TransactionDirection} from '../../data/statistic';
import {Input} from '../../component/input';
import {Checkbox} from '../../component/checkbox';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {UserRole} from '../../data/user';
import {useAuthContext} from '../../context/auth';

type EditBookmark = TransactionBookmark & { index: number }

interface BookmarkFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection & {
        amount: HTMLInputElement;
        message: HTMLInputElement;
        customAmountGranted: HTMLInputElement;
        customMessageGranted: HTMLInputElement;
        inAppPaymentExclusive: HTMLInputElement;
    };
}

export function ClubTransactionBookmarks({club: {id, transactionBookmarks: bookmarks }, onUpdate}: { club: Club, onUpdate: () => Promise<void> }) {
    const {t} = useLocalization();
    const {
        setTransactionBookmarks,
    } = useClubApi();
    const {role} = useAuthContext();

    const [bookmark, setBookmark] = useState<EditBookmark>();
    const [bookmarkDirection, setBookmarkDirection] = useState<TransactionDirection>();
    const [deleteBookmarkIndex, setDeleteBookmarkIndex] = useState<number>();

    function handleBookmarkSelect(b: TransactionBookmark, index: number) {
        setBookmark({...b, index });
        setBookmarkDirection(b.direction);
    }

    function handleBookmarkAdd() {
        setBookmark({
            index: bookmarks.length ?? 0,
            direction: TransactionDirection.BookOut,
            customAmountGranted: false,
            customMessageGranted: false,
            inAppPaymentExclusive: false
        });
        setBookmarkDirection(TransactionDirection.BookOut);
    }

    function handleBookmarkDelete() {
        const request = bookmarks.filter((_, index) => index !== deleteBookmarkIndex);

        setTransactionBookmarks(id, request)
            .then(async () => { await onUpdate(); })
            .catch(console.error)
            .finally(() => { setDeleteBookmarkIndex(undefined); });
    }

    async function handleBookmarkSubmit(event_: React.FormEvent<BookmarkFormElement>) {
        event_.preventDefault();

        if (bookmarks !== undefined
            && bookmark !== undefined
            && bookmarkDirection !== undefined
        ) {
            const formElements = event_.currentTarget.elements;

            const data: TransactionBookmark = {
                amount: formElements.amount.valueAsNumber,
                message: formElements.message.value,
                direction: bookmarkDirection,
                customAmountGranted: formElements.customAmountGranted.checked,
                customMessageGranted: formElements.customMessageGranted.checked,
                inAppPaymentExclusive: formElements.inAppPaymentExclusive.checked,
            };

            const request = bookmark.index === bookmarks.length
                ? [...bookmarks, data]
                : bookmarks.map((b, index) => index === bookmark.index ? data : b);

            await setTransactionBookmarks(id, request);
            await onUpdate();

            setBookmark(undefined);
        }
    }

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
            >
                <Button
                    size="sm"
                    variant="outlined"
                    startDecorator={<AddRoundedIcon />}
                    onClick={handleBookmarkAdd}
                    disabled={role === UserRole.User}
                >
                    {t('payments.bookmarks.addNew')}
                </Button>
            </Stack>
            <Box my={2} />
            <PlainTable
                stickyLastColumn
                items={bookmarks}
                loading={false}
                error={undefined}
                headers={[
                    { label: t('common.direction'), width: 120 },
                    { label: t('common.type'), width: 100 },
                    { label: t('common.amount'), width: 100 },
                    { label: t('transactions.purpose'), width: 160 },
                    { label: t('payments.bookmarks.customAmountGranted'), width: 100 },
                    { label: t('payments.bookmarks.customMessageGranted'), width: 100 },
                    ...(role === UserRole.User
                            ? []
                            : [{ label: '', width: 60 }]
                    )
                ]}
                renderTableRow={(b, index) => (
                    <BookmarkTableRow
                        key={`bookmark-${index}`}
                        bookmark={b}
                        onSelect={() => { handleBookmarkSelect(b, index); }}
                        onDelete={() => { setDeleteBookmarkIndex(index); }}
                    />
                )}
                renderListRow={(b, index) => (
                    <BookmarkListRow
                        key={`bookmark-${index}`}
                        bookmark={b}
                        onSelect={() => { handleBookmarkSelect(b, index); }}
                    />
                )}
            />
            <FormModal
                open={bookmark !== undefined}
                onCancel={() => { setBookmark(undefined); }}
                onSave={handleBookmarkSubmit}
                disabled={role === UserRole.User}
            >
                <Typography level="title-lg">
                    {bookmark?.index === bookmarks.length
                        ? t('payments.bookmarks.addNew')
                        : t('actions.edit')
                    }
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6}>
                        <Select
                            label={t('common.direction')}
                            options={Object.values(TransactionDirection)}
                            renderOption={o => (
                                <Option key={o} value={o}>{t(`transactions.directions.${o}`)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: bookmarkDirection,
                                onClear: () => { setBookmarkDirection(undefined); },
                                onChange: (_, value) => { setBookmarkDirection(value ?? undefined); },
                                renderValue: o =>
                                    o === null
                                        ? ''
                                        : t(`transactions.directions.${o.value}`)
                            }}
                            FormControlProps={{
                                required: true,
                                disabled: role === UserRole.User
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Input
                            label={t('common.amount')}
                            InputProps={{
                                startDecorator: '€',
                                type: 'number',
                                inputMode: 'numeric',
                                slotProps: {
                                    input: {
                                        step: '.01'
                                    }
                                },
                                name: 'amount',
                                defaultValue: bookmark?.amount
                            }}
                            FormControlProps={{
                                disabled: role === UserRole.User
                            }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Input
                            label={t('transactions.purpose')}
                            InputProps={{
                                name: 'message',
                                defaultValue: bookmark?.message
                            }}
                            FormControlProps={{
                                disabled: role === UserRole.User
                            }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Checkbox
                            CheckboxProps={{
                                label: t('payments.bookmarks.inAppPaymentExclusive'),
                                name: 'inAppPaymentExclusive',
                                defaultChecked: bookmark?.inAppPaymentExclusive,
                            }}
                            FormControlProps={{
                                disabled: role === UserRole.User
                            }}
                            helperText={t('payments.bookmarks.inAppPaymentExclusiveHint')}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Checkbox
                            CheckboxProps={{
                                label: t('payments.bookmarks.customAmountGranted'),
                                name: 'customAmountGranted',
                                defaultChecked: bookmark?.customAmountGranted,
                            }}
                            FormControlProps={{
                                disabled: role === UserRole.User
                            }}
                            helperText={t('payments.bookmarks.customAmountGrantedHint')}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Checkbox
                            CheckboxProps={{
                                label: t('payments.bookmarks.customMessageGranted'),
                                name: 'customMessageGranted',
                                defaultChecked: bookmark?.customMessageGranted,
                            }}
                            FormControlProps={{
                                disabled: role === UserRole.User
                            }}
                            helperText={t('payments.bookmarks.customMessageGrantedHint')}
                        />
                    </Grid>
                </Grid>
            </FormModal>
            <ConfirmDialog
                open={deleteBookmarkIndex !== undefined}
                title={t('payments.bookmarks.deleteTitle')}
                message={t('payments.bookmarks.deletePrompt')}
                onConfirm={handleBookmarkDelete}
                onCancel={() => { setDeleteBookmarkIndex(undefined); }}
                color="danger"
            />
        </>
    );
}

function BookmarkTableRow(properties: { bookmark: TransactionBookmark, onSelect: () => void, onDelete: () => void }) {
    const {
        bookmark: {
            direction,
            inAppPaymentExclusive,
            amount,
            message,
            customMessageGranted,
            customAmountGranted
        },
        onSelect,
        onDelete
    } = properties;
    const {t} = useLocalization();
    const {role} = useAuthContext();

    return (
        <tr
            onClick={onSelect}
        >
            <td>
                <Typography level="body-xs">{t(`transactions.directions.${direction}`)}</Typography>
            </td>
            <td>
                <Typography
                    level="body-xs">{inAppPaymentExclusive ? t('transactions.purposes.InAppPayment') : t('common.all')}</Typography>
            </td>
            <td>
                {notBlank(amount) && (
                    <Typography
                        level="body-xs"
                        lineHeight="1em"
                        startDecorator={<EuroRoundedIcon/>}
                    >
                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                        {displayAmount(amount!)}
                    </Typography>
                )}
            </td>
            <td>
                <Typography level="body-xs">{message}</Typography>
            </td>
            <td>
                <StatusChip enabled={customAmountGranted}/>
            </td>
            <td>
                <StatusChip enabled={customMessageGranted}/>
            </td>
            {role !== UserRole.User && (
                <td
                    onClick={event_ => {
                        event_.stopPropagation();
                    }}
                >
                    <ItemMenu
                        {...{
                            onSelect,
                            onDelete,
                        }}
                    />
                </td>
            )}
        </tr>
    );
}

function BookmarkListRow(properties: { bookmark: TransactionBookmark, onSelect: () => void }) {
    const {
        bookmark: {
            direction,
            inAppPaymentExclusive,
            amount,
            message
        },
        onSelect
    } = properties;
    const {t} = useLocalization();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={onSelect}
            >
                <ListItemContent>
                    <Typography level="body-xs">{t(`transactions.directions.${direction}`)}</Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 1 }}>
                        {inAppPaymentExclusive ? t('transactions.purposes.InAppPayment') : t('common.all')}
                    </Typography>
                    <Typography level="body-xs">{message}</Typography>
                </ListItemContent>

                {notBlank(amount) && (
                    <Typography
                        level="body-sm"
                        lineHeight="1em"
                        startDecorator={<EuroRoundedIcon fontSize="inherit" />}
                    >
                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                        {displayAmount(amount!)}
                    </Typography>
                )}
            </ListItemButton>
            <ListDivider />
        </List>
    );
}