import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {useAsync} from 'react-async-hook';
import {Box, Button, Divider, Grid, Sheet, Stack, Typography} from '@mui/joy';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import dayjs, {type Dayjs} from 'dayjs';
import {Input} from '../../component/input';
import {type AccountGroupRequest, useAccountGroupApi} from '../../data/account-group';
import {IsoDateFormat, notBlank} from '../../utils';
import {useLocalization} from '../../context/localization';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {DatePicker} from '../../component/date-picker';
import {TimePicker} from '../../component/time-picker';
import {AuthWrapper} from '../../context/auth';
import {UserRole} from '../../data/user';
import {Checkbox} from '../../component/checkbox';


export function AccountGroupEditor() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root, UserRole.Admin ]}>
            <ContentView />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
    defaultOnSignup: HTMLInputElement;
    customRangefeeAmount: HTMLInputElement;
}

interface AccountGroupEditorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView() {
    const {id } = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {find, put, deleteAccountGroup} = useAccountGroupApi();

    const {result: group, error: loadError, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    /* eslint-disable unicorn/no-null */
    const [expirationDate, setExpirationDate] = useState<Dayjs|null>(null);
    const [startHour, setStartHour] = useState<Dayjs|null>(null);
    const [endHour, setEndHour] = useState<Dayjs|null>(null);
    /* eslint-enable unicorn/no-null */

    useEffect(() => {
        if (group !== undefined) {
            if (notBlank(group.expirationDate)) setExpirationDate(dayjs(group.expirationDate));
            if (notBlank(group.startHour)) setStartHour(dayjs(group.startHour, IsoDateFormat.Time));
            if (notBlank(group.endHour)) setEndHour(dayjs(group.endHour, IsoDateFormat.Time));
        }
    }, [group]);

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);
    const [error, setError] = useState<Error>();

    function handleFormSubmit(event: React.FormEvent<AccountGroupEditorFormElement>) {
        event.preventDefault();

        if (group !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: AccountGroupRequest = {
                name: formElements.name.value,
                expirationDate: expirationDate?.format(IsoDateFormat.Date),
                startHour: startHour?.format(IsoDateFormat.Time),
                endHour: endHour?.format(IsoDateFormat.Time),
                defaultOnSignup: formElements.defaultOnSignup.checked,
                customRangefeeAmount: formElements.customRangefeeAmount.valueAsNumber,
                clubId: group.clubId
            };

            put(group.id, data)
                .then(() => { navigate(`/account-groups/${group.id}`); })
                .catch(error_ => {
                    console.error('Failed to put group data', error_);
                    setError(new Error('Failed to update.'));
                });
        }
    }

    function handleDelete() {
        if (group !== undefined) {
            deleteAccountGroup(group.id)
                .then(() => { navigate('/account-groups'); })
                .catch(setError);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('accountGroups.title'), onClick: () => { navigate('/account-groups'); } },
                        { label: group === undefined ? t('common.details') : group.name, onClick: () => { navigate(`/account-groups/${id}`); } },
                        { label: t('actions.edit') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={group === undefined ? t('accountGroups.singular') : group.name}
                    actions={(
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<ArrowBackIosRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/account-groups/${group?.id}`); }}
                        >
                            {t('actions.back')}
                        </Button>
                    )}
                />
            )}
            loading={loading}
            error={error ?? loadError}
        >
            {group !== undefined && (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <Sheet
                            variant="outlined"
                            sx={{
                                p: 2
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Typography level="title-lg">{t('common.details')}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Input
                                        label={t('common.name')}
                                        InputProps={{
                                            name: 'name',
                                            defaultValue: group.name
                                        }}
                                        FormControlProps={{
                                            required: true
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <Input
                                        label={t('transactions.customRangefeeAmount')}
                                        InputProps={{
                                            name: 'customRangefeeAmount',
                                            startDecorator: '€',
                                            type: 'number',
                                            inputMode: 'numeric',
                                            defaultValue: group.customRangefeeAmount ?? '',
                                            slotProps: {
                                                input: {
                                                    step: '.01'
                                                }
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Checkbox
                                        CheckboxProps={{
                                            label: t('accountGroups.defaultOnSignup'),
                                            name: 'defaultOnSignup',
                                            defaultChecked: group.defaultOnSignup,

                                        }}
                                        helperText={t('accountGroups.defaultOnSignupHint')}
                                    />
                                </Grid>

                            </Grid>
                            <Divider sx={{my: 3, mx: -2}}/>
                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Typography level="title-lg">{t('common.settings')}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <DatePicker
                                        label={t('accountGroups.expirationDate')}
                                        PickerProps={{
                                            value: expirationDate,
                                            onChange: setExpirationDate
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Box my={2} />
                                    <Typography>{t('accountGroups.activeTimeframe')}</Typography>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <TimePicker
                                        label={t('common.from')}
                                        PickerProps={{
                                            value: startHour,
                                            onChange: setStartHour
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <TimePicker
                                        label={t('common.until')}
                                        PickerProps={{
                                            value: endHour,
                                            onChange: setEndHour
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Sheet>
                        <Divider sx={{my: 2}}/>
                        <Stack
                            direction={{sm: 'column', md: 'row'}}
                            gap={2}
                        >
                            <Button
                                startDecorator={<SaveRoundedIcon/>}
                                type="submit"
                            >
                                {t('actions.save')}
                            </Button>
                            <Button
                                startDecorator={<CloseRoundedIcon/>}
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    navigate(`/account-groups/${group?.id}`);
                                }}
                            >
                                {t('actions.cancel')}
                            </Button>
                            <Box flexGrow={1} sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}/>
                            <Button
                                startDecorator={<DeleteRoundedIcon />}
                                variant="outlined"
                                color="danger"
                                onClick={setDeletionPromptOpen}
                            >
                                {t('actions.delete')}
                            </Button>
                        </Stack>
                    </form>
                    <ConfirmDialog
                        open={deletionPromptOpen}
                        onCancel={clearDeletionPromptOpen}
                        onConfirm={handleDelete}
                        title={t('accountGroups.deleteTitle')}
                        message={t('accountGroups.deletePrompt')}
                        color="danger"
                    />
                </>
            )}
        </Body>
    );
}