import React, {useState} from 'react';
import {Box, IconButton, List, ListDivider, ListItemButton, ListItemContent, Typography} from '@mui/joy';
import {DateTimeDisplay, PlainTable} from '@mlyngvo/common-ui';
import {useLocalization} from '../../context/localization';
import {type AccountTransaction, type TransactionPurpose, TransactionType} from '../../data/statistic';
import {TransactionModal} from '../statistic/transaction-modal';
import {notBlank} from '../../utils';
import {TransactionStatusChip} from '../statistic/transaction-status-chip';
import {TransactionTypeChip} from '../statistic/transaction-type-chip';
import {TransactionPurposeChip} from '../statistic/transaction-purpose-chip';
import {ClubLabel} from '../../component/club-label';
import {ColoredAmount} from '../../component/colored-amount';
import {RefreshRounded} from "@mui/icons-material";

export function RecentTransactions({transactions, onRefresh}: { transactions: AccountTransaction[], onRefresh: () => Promise<void> }) {
    const {t} = useLocalization();

    const [transaction, setTransaction] = useState<AccountTransaction>();

    return (
        <Box sx={{ maxHeight: { xs: 'none', sm: 500 }, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            {}

            <Typography
                level="title-md"
                endDecorator={<IconButton onClick={onRefresh}><RefreshRounded /></IconButton>}
            >
                <strong>{t('stats.recentTransactions')}</strong>
            </Typography>
            <PlainTable
                items={transactions}
                loading={false}
                error={undefined}
                headers={[
                    { label: t('common.timestamp'), width: 160 },
                    { label: t('accounts.singular'), width: 140 },
                    { label: t('wallets.singular'), width: 80 },
                    { label: t('common.type'), width: 120 },
                    { label: t('transactions.purpose'), width: 160 },
                    { label: t('clubs.singular'), width: 120 },
                    { label: t('common.amount'), width: 140 },
                ]}
                renderTableRow={at => <TransactionTableRow key={at.uuid} {...{ transaction: at, onClick: () => { setTransaction(at); } }} />}
                renderListRow={at => <TransactionListRow key={at.uuid} {...{ transaction: at, onClick: () => { setTransaction(at); } }} />}
            />
            {transaction !== undefined && <TransactionModal transaction={transaction} onClose={() => { setTransaction(undefined); }} />}
        </Box>
    );
}

function TransactionTableRow({transaction: {timestamp, type, clearedAt, accountName, walletType, note, data, clubName, clubId}, onClick}: { transaction: AccountTransaction, onClick: () => void }) {
    const {t, language} = useLocalization();
    const isNegative = type === TransactionType.SubtractCredit || type === TransactionType.ConsumeCredit;
    return (
        <tr style={{cursor: 'pointer'}} onClick={onClick}>
            <td>
                <Typography level="body-xs" gutterBottom>
                    <DateTimeDisplay value={timestamp} locale={language} />
                </Typography>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </td>
            <td>
                <Typography level="body-xs">{accountName}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{t(`wallets.types.${walletType}`)}</Typography>
            </td>
            <td>
                <TransactionTypeChip type={type}/>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{note}</Typography>
                {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose as TransactionPurpose}/>}
            </td>
            <td>
                {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap />}
            </td>
            <td>
                <ColoredAmount amount={data.amount} negative={isNegative} disabled={!notBlank(clearedAt)}/>
            </td>
        </tr>
    );
}

function TransactionListRow({transaction: {timestamp, type, clearedAt, accountName, note, data, clubName, clubId}, onClick}: { transaction: AccountTransaction, onClick: () => void }) {
    const {language} = useLocalization();
    const isNegative = type === TransactionType.SubtractCredit || type === TransactionType.ConsumeCredit;
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={onClick}
            >
                <ListItemContent>
                    <Typography level="body-xs">
                        <DateTimeDisplay value={timestamp} locale={language} />
                    </Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{note}</Typography>
                    <ColoredAmount amount={data.amount} negative={isNegative} disabled={!notBlank(clearedAt)}/>
                    <Box my={1} />
                    <TransactionTypeChip type={type}/>
                    {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose as TransactionPurpose}/>}
                    <Box my={1} />
                    <Typography level="body-sm">{accountName}</Typography>
                    {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap />}
                </ListItemContent>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}