import type {DeviceType} from './device';

export interface Address {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    zipCode?: string;
    country?: string;
}

export interface GeoLocation {
    longitude?: number;
    latitude?: number;
}

export interface ContactDetails {
    email?: string;
    phone?: string;
}

export enum TimeUnit {
    Millisecond = 'Millisecond',
    Second = 'Second',
    Minute = 'Minute',
    Hour = 'Hour'
}

export interface DevicePermissionItem {
    deviceId: string;
    deviceName: string;
    deviceType: DeviceType;
    enabled: boolean;
}

export enum CostAdjustmentDirection {
    Discount = 'Discount',
    Markup = 'Markup',
    FixedPrice = 'FixedPrice',
}

export enum CostAdjustmentType {
    Percentage = 'Percentage',
    Amount = 'Amount',
}

export interface CostAdjustmentItem {
    deviceId: string;
    deviceName: string;
    deviceType: DeviceType;
    direction: CostAdjustmentDirection;
    type?: CostAdjustmentType;
    port0Amount: number;
    port1Amount: number;
    port2Amount: number;
    port3Amount: number;
}

export interface CostAdjustmentRequest {
    direction: CostAdjustmentDirection;
    type?: CostAdjustmentType;
    port0Amount: number;
    port1Amount: number;
    port2Amount: number;
    port3Amount: number;
}