import {CostAdjustmentDirection, CostAdjustmentType} from "../../data/common";
import {Grid, Stack, Typography} from "@mui/joy";
import {displayAmount, notBlank} from "../../utils";
import {Input} from "../../component/input";
import React, {useMemo} from "react";
import {useLocalization} from "../../context/localization";

interface CostAdjustmentInputProps {
    baseAmount: number;
    adjustValue: number;
    onChange: (value: string) => void;
    direction?: CostAdjustmentDirection;
    type?: CostAdjustmentType;
}

export function CostAdjustmentInput({baseAmount, adjustValue, onChange, direction, type}: CostAdjustmentInputProps) {
    const {t} = useLocalization();

    const endPrice = useMemo(() => {
        let calculated = baseAmount;
        if (adjustValue > 0) {
            if (direction === CostAdjustmentDirection.FixedPrice) {
                calculated = adjustValue;
            }
            else if (direction === CostAdjustmentDirection.Discount) {
                if (type === CostAdjustmentType.Percentage) {
                    calculated = baseAmount - (baseAmount * adjustValue  / 100);
                }
                else if (type === CostAdjustmentType.Amount) {
                    calculated = baseAmount - adjustValue;
                }
            }
            else if (direction === CostAdjustmentDirection.Markup) {
                if (type === CostAdjustmentType.Percentage) {
                    calculated = baseAmount + (baseAmount * adjustValue  / 100);
                }
                else if (type === CostAdjustmentType.Amount) {
                    calculated = baseAmount + adjustValue;
                }
            }
        }
        return Math.max(calculated, 0);
    }, [baseAmount, adjustValue, direction, type]);

    function handleValueChange(value: string) {
        onChange(value);
    }

    return (
        <Grid xs={12} sm={3}>
            <Stack
                direction="column"
                gap={2}
            >
                <div>
                    <Typography level="body-sm" gutterBottom><strong>{t('devices.basePrice')}</strong></Typography>
                    € {displayAmount(baseAmount)}
                </div>
                <Input
                    label={t('common.value')}
                    InputProps={{
                        startDecorator: (type === CostAdjustmentType.Amount || direction === CostAdjustmentDirection.FixedPrice) ? '€' : undefined,
                        endDecorator: type === CostAdjustmentType.Percentage ? '%' : undefined,
                        type: 'number',
                        inputMode: 'numeric',
                        slotProps: {
                            input: {
                                step: '.01'
                            }
                        },
                        value: adjustValue,
                        onChange: event_ => handleValueChange(event_.target.value)
                    }}
                />
                <div>
                    <Typography level="body-sm" gutterBottom><strong>{t('devices.finalPrice')}</strong></Typography>
                    € {displayAmount(endPrice)}
                </div>
            </Stack>
        </Grid>
    )
}